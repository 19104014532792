body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

header h1 {
  margin: 0;
  font-size: 1.5em;
}

main {
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

label {
  margin: 10px 0 5px;
}

input,
select,
textarea,
button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

@media (max-width: 600px) {
  form {
    width: 90%;
  }
}

.payer-info-container {
  position: fixed;
  top: 120px;
  right: 10px;
  z-index: 1;
}

.payer-info {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  text-align: right;
}

.payer-info h5 {
  margin-top: 0;
}

.payer-info p {
  margin-bottom: 5px;
}

.submitted-reports {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.submitted-reports h3 {
  cursor: pointer;
  margin: 0;
  padding: 10px;
  background-color: #8e9194c9;
  color: white;
  border-radius: 4px;
  text-align: left;
}

.submitted-reports ul {
  list-style-type: none;
  padding: 0;
  display: none;
}

.submitted-reports.active ul {
  display: block;
}

.submitted-reports li {
  margin-bottom: 10px;
  cursor: pointer;
}

.submitted-reports .report-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.submitted-reports .report-item:hover {
  background-color: #f1f1f1;
}

.submitted-reports .report-details {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.report-divider {
  border-top: 2px solid #007bff;
  margin: 20px 0;
}

.report-divider-closed {
  border-top: 2px solid #28a745;
  margin: 20px 0;
}

.report-details p {
  margin: 5px 0;
}

.submitted-reports a {
  color: #007bff;
  text-decoration: none;
}

.submitted-reports a:hover {
  text-decoration: underline;
}

.report-counts {
  text-align: left;
  margin-bottom: 20px;
}

.report-counts p {
  margin: 5px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .submitted-reports .report-item a {
    word-wrap: break-word;
  }
}
